<template>
  <base-section
    id="features"
    class="bgFeature gradient"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <v-row
          justify="center"
        >
          <v-col
            v-for="card in cards"
            :key="card.title"
            cols="12"
            sm="4"
            md="4"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
              :urlVideo="card.video"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cards: [
        {
          title: 'ClassLink',
          video: 'https://www.youtube.com/embed/dCgoOKty2SM',
        },
        {
          title: 'edpuzzle',
          video: 'https://www.youtube.com/embed/odQw6IWLXdY',
        },
        {
          title: 'i-Ready',
          video: 'https://www.youtube.com/embed/_aFfs4SU_fE',
        },
      ],
    }),
  }
</script>

<style scoped>
.gradient {
  background: rgb(232,188,27);
  background: linear-gradient(90deg, rgba(232,188,27,1) 0%, rgba(241,160,27,1) 35%);
}
</style>
